import React, { useEffect, useState } from "react";
import { Keyboard } from "./keyboard.js";
import 'react-piano/dist/styles.css';
import "./piano.css";
import { getQueryVariable, userTypes } from "./Utils.js";
import { socketOn } from "../socket/index.js";


let roomName = '';

let getRoom = getQueryVariable("roomName");
let _getRoom = getQueryVariable("room_name");
if (getRoom != '' && getRoom != undefined) {
  roomName = getRoom;
} else if (_getRoom != '' && _getRoom != undefined) {
  roomName = _getRoom;
}

// This code is using for web and mobile in case of we have already 
//managed the UI at web end to just adding the css for we only.

// just send the key : hasWeb in thr query string

let isWeb = false;
let hasWeb = getQueryVariable("hasWeb");

if (hasWeb != '' && hasWeb != undefined) {
  isWeb = true;
}


let isAllow = false;
let isAccessable = getQueryVariable("isUserAllow");

if (isAccessable != '' && isAccessable != undefined) {
  isAllow = true;
}

let hasColor = getQueryVariable("color");
if (hasColor != '' && hasColor != undefined) {
  let custom = document.createElement("style");
  let regex = new RegExp("^[a-z][a-z' -]*", "gi");

  if (!regex.test(hasColor)) {
    hasColor = "#" + hasColor
  }

  const newContent = document.createTextNode(".ReactPiano__Key--active { background-color : " + hasColor + " !important} .btn-primary { background-color : " + hasColor + " !important;border-color : " + hasColor + " !important;}");

  custom.appendChild(newContent);
  document.head.appendChild(custom);
}



const Piano = ({ socket, Sessions, closeTool }) => {
  const { openByUser, SessionId } = Sessions;
  const [isDisable, setIsDisable] = useState(false);
  const [isUserAllowed, setuserAllowed] = useState(false);

  useEffect(() => {
    if (isWeb) {
      if (isAllow) {
        setuserAllowed(true)
      }
    } else {
      setuserAllowed(true)
    }

    return function cleanup() {
      socket.off('played_note_return');
      socket.off('played_note_send');
    };
  }, [])

  const handleUserAction = () => {
    setuserAllowed(true)
  }


  return (
    <div className={"piano-container " + (isWeb ? " web-piano-container" : '')} id="piano-container" >
      {/*  if disable piano to play for student */}
      {
        !isUserAllowed ?
          <>
            <div
              className="disable-ly"
            ></div>
            <div className="piano-over-lay">
              <div
                className="user-permission"
              >
                <p>Allow Piano to play automatically</p>
                <button className="btn-primary"
                  onClick={handleUserAction}
                >
                  Allow
                </button>
              </div>
            </div>
          </>
          : ""}
      <Keyboard
        socket={socket}
        Sessions={Sessions}
        closeTool={closeTool}
      />
    </div>
  )
}

export default Piano;
